.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Home {
  font-family: 'Teko', sans-serif;
}